.nav__list {
  margin-right: 1.5em;
  display: flex;
}
.nav__list-item {
  margin-left: 1.5em;
}

.app .nav__hamburger {
  display: none;
}

.nav__theme {
  margin-top: 0.4em;
}
/* Define a blinking animation */
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

/* Apply the blinking animation to the News button */
.nav__list-item--news a {
  animation: blink 1.5s infinite; /* Blinking every 1.5 seconds */
  color: #ff4500; /* Optional: Change the color to make it more noticeable */
}

/* You can add a hover effect if you want it to stop blinking on hover */
.nav__list-item--news a:hover {
  animation: none; /* Stops blinking on hover */
  color: #ff6347; /* Optional: Change color on hover */
}

@media (max-width: 600px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }
}
